<!-- <material-navbar
    [menuPosition]="settings.get('vebto.navbar.defaultPosition')"
    [container]="true"
></material-navbar> -->

<div class="be-container page-container">
    <h1>{{ group?.name }} <button class="no-print" (click)="onPrint()">print</button></h1>

    <p class="group-description" *ngIf="group?.description">{{ group.description }}</p>
    <br />

    <div class="links-container">
        <ul
            class="group-links skeleton-links unstyled-list"
            *ngIf="!links && (loading$ | async)"
            @fadeOut
        >
            <li class="group-link skeleton-link" *ngFor="let i of [1, 2, 3, 4]">
                <skeleton class="short-url" variant="text"></skeleton>
                <skeleton class="long-url" variant="text"></skeleton>
                <skeleton class="link-description" variant="text"></skeleton>
            </li>
        </ul>

        <div class="group-links real-links unstyled-list" @fadeIn>
            <div class="group-link page-break" *ngFor="let link of links?.data">
                <div>
                    <a class="long-url-button" [href]="link.short_url" target="_blank">
                        <div class="long-url">{{ link.short_url }}</div>
                    </a>
                    <div class="short-url">
                        <img
                            class="favicon-img"
                            [src]="link.long_url | favicon"
                            alt="Link favicon"
                        />
                        <span>{{ removeProtocol(link.long_url) }}</span>
                    </div>

                    <ul class="link-description">
                        <li *ngFor="let tag of link?.tags">{{ tag.name }}</li>
                    </ul>

                    <p class="link-description" *ngIf="link.title">
                        {{ link.title }}
                    </p>
                </div>
                <div>
                    <a class="text-center" [href]="link.short_url + '/qr/jpg'">
                        <img
                            width="200"
                            [src]="link.short_url + '/qr'"
                            alt="link.title"
                        />
                        <p class="link-description no-print">Download</p>
                    </a>
                </div>
            </div>
        </div>
    </div>

    <loading-indicator
        [isVisible]="links && (loading$ | async)"
        class="inline"
    ></loading-indicator>
</div>
<br />
<br /><br /><br />
<!-- <app-footer class="be-container"></app-footer> -->
